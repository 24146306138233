import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
const mapKey="AIzaSyAYD50UWZ6XU94hv_sqjFZqksOwaTYgsXs";


class GoogleMaps extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
      return (
        
        <div className="google-map">
          <GoogleMapReact
            bootstrapURLKeys={{ key: mapKey}}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
    
          >
          {this.props.features}
          </GoogleMapReact>
        </div>
      )

}
}
export default GoogleMaps;