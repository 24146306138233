import axios from 'axios';
const {get, set} = require('./session')
const setThisSession = {}
let tokenTimerInit = false
const sleep = async (ms) => {
    await new Promise(resolve => setTimeout(resolve, ms));
  }
  
const tokenTimeoutTimer = async (auth) => {
    const log = window.log("Token Timer")
    if (!tokenTimerInit) {
        tokenTimerInit = true; //Make sure this only runs once on init
        const authSession = get("auth")
        const accessToken = authSession.credential.accessToken
        let status = {}
        try {
            status = await axios.get(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessToken}`)
        } catch (e) {
            log.log(e)
            status.error = true
        }
        if (status?.error === true | status?.status !== 200 | status?.data?.expires_in < 30 ) { //Token is invalid or expires in less than 30 seconds
            log.log("Google session expired, logging out.")
            await auth.signOut()
        }
        await sleep(status?.data?.expires_in * 1000) //sleep for remaining seconds
        log.log("Google session timer complete.")
        await auth.signOut(); //signout after expiration
    }
}
const init = async (firebase, user) => {
    const log = window.log("Init")
    const auth = firebase.auth()
    if (user) {
        const currentTime = Date.now()
        if (user.stsTokenManager.expirationTime < currentTime) {
            log.log("Google session expired, logging out.")
            await auth.signOut()
        }
        tokenTimeoutTimer(auth)

    }
    if (!setThisSession.maps) {
        const db=firebase.firestore();
        const resp = await db.collection('userData').doc(user.uid).collection("maps").doc("conf").get()
        const conf = resp.data()
        set(conf, 'maps')
        setThisSession.maps = true
    }

}

export {init}