import React from 'react';
import { Button } from 'react-bootstrap';
const session = require('../lib/session')


const Refresh = ({props}) => {
  const log = window.log("Refresh")
  const refresh = async () => {
    const sessionInfo = session.get("auth");
    const conf = session.get("maps");
    log.log({conf})
    const promises = conf.mapsConf.map(async (mapInfo) => {
      log.log({mapInfo})
      const sheetData = {sheetID: mapInfo.sheetID ,addressFormat: mapInfo.addressFormat, accessToken: sessionInfo.credential.accessToken, idToken: sessionInfo.credential.idToken, refresh: true}
      const refresh =  await props.firebase.functions().httpsCallable('sheets-getSheetData')(sheetData)
      log.log(refresh) //Log retried data, note it will be called again after the reload and shown on the map.
    })
    await Promise.all(promises)
    window.location.reload();
  }
  return (
    <>
      <div  className="d-grid gap-2 navItem">
      <Button variant="primary" onClick={refresh}>
        Refresh
      </Button>
      </div>
    </>
  )
}




export default Refresh;