import React from 'react';
import { Button } from 'react-bootstrap';

const session = require('../../lib/session');



const Auth = (props) => {
  const log = window.log("Auth")
    const SignIn = () => {
        const signInWithGoogle = () => {
          log.log("Sign on requested")
          const provider = new props.firebase.auth.GoogleAuthProvider();
          provider.addScope([ //Sheets API
            'https://www.googleapis.com/auth/drive',
            'https://www.googleapis.com/auth/drive.file',
            'https://www.googleapis.com/auth/drive.readonly',
            'https://www.googleapis.com/auth/spreadsheets',
            'https://www.googleapis.com/auth/spreadsheets.readonly',
          ])
          props.auth.signInWithPopup(provider).then((result) => {
            log.log({login: true, result})
            result.sessionActive = true
            session.set(result, "auth")
            window.location.reload(false);
              
           });
        }
        return (

            <Button className="sign-in authButton navItem" onClick={signInWithGoogle}>Sign In</Button>

        )
      }
      const SignOut = () => {
        log.log("sign out")
        return props.auth.currentUser && (
          
          <Button className="sign-out authButton navItem" onClick={() => {
            session.set({}, "auth")
            props.auth.signOut()}}>Sign Out</Button>
          
        )
      }
      

    if (props.user) {
        return <SignOut/>
    } else {
        return <SignIn />
    }
    
}


export default Auth;