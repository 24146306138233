module.exports = class Logger {
    init(shortMessage, logMeta) {
        this.endpoint = 'https://log.wal-sys.com/post_log'
        this.headers = { 'content-type': 'application/json' }
        this.short_message = shortMessage
        this.logMeta = logMeta
    }
    log(message, level) {
        console.log("logMeta")
        console.log(this.logMeta)
        let payload = {
            level,
            short_message: this.short_message,
            full_message: message,
            logMeta: this.logMeta
        }
        return { payload, headers: this.headers, endpoint: this.endpoint }
    }
}