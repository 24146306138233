import React, {useState} from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
const session = require('../lib/session')
// const log = window.log("conf")

const Conf = ({props}) => {
  const log = window.log("Conf")
  const baseConf = {
    sheetID: "",
    addressFormat: "",
    featureColor: "red"
  }   
    const [show, setShow] = useState(false);
    const [formData, setForm] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const getConf = async (props) => {
      const db=props.firebase.firestore();
      const docRef = db.collection('userData').doc(props.user.uid).collection("maps").doc("conf")
      const conf = await db.collection('userData').doc(props.user.uid).collection("maps").doc("conf").get()
      log.log({conf})
      const data = conf.data()
      if (Array.isArray(data?.mapsConf)) { //conf array exists
        setForm(data.mapsConf)
      } else {

        const finalConf = {mapsConf: [{...baseConf, ...data}]}
        log.log({finalConf})
        session.set(finalConf, 'maps')
        setForm(finalConf.mapsConf)
        await docRef.set(finalConf);
      }

    }
    if (typeof formData !== "object" || !Array.isArray(formData)) {
      getConf(props)
      return <div>Loading...</div>
    } else {

    const handleChange = (value, field, index) => { //Saves form to state
      index-- //reduce index, for some reason it is always 1 higher than it should be
      const currentForm = JSON.parse(JSON.stringify(formData)) //Deep clone current
      currentForm[index][field] = value //Set field to value
      setForm(currentForm) //Set state to new form values
    }
    const addMap = () => { //Adds a new map to the form
      const currentForm = JSON.parse(JSON.stringify(formData)) //Deep clone current
      currentForm.push(baseConf) //Add new map
      setForm(currentForm) //Set state to new form values
    }
    const deleteMap = (index) => { //Deletes map from state
      index-- //reduce index, for some reason it is always 1 higher than it should be
      const currentForm = JSON.parse(JSON.stringify(formData)) //Deep clone current
      currentForm.splice(index, 1) //Remove map from array
      setForm(currentForm) //Set state to new form values
    }
    const handleSubmit = async (e) => { //Sync firestore with form values
      log.log("Saving form to firestore")
      e.preventDefault()
      const asyncWrap = async (e) => {
        const conf = {mapsConf: formData}
        setForm(conf)
        session.set(conf, 'maps')
        const db=props.firebase.firestore();
        const docRef = db.collection('userData').doc(props.user.uid).collection("maps").doc("conf")
        await docRef.set(conf);
        //await new Promise(r => setTimeout(r, 2000)); //Sleep to allow DB Propogation
        handleClose()
        window.location.reload()
      }
      asyncWrap(e)
    }
    const confFields = []
    let i = 0
    formData.forEach(conf => {
      log.log({i})
      confFields.push(
        <div className={i} style={{paddingTop: "1rem", paddingBottom: "1rem"}}>
          <Form.Group className="mb-3" controlId="sheetID">
          <Form.Label>Sheet ID</Form.Label> <Button style={{ background: "none", color: "red", border: "none", font: "inherit", outline: "inherit", marginBottom: ".5rem"}} onClick={() => deleteMap(i)}>X</Button>
          <Form.Control name="sheetID" type="text" defaultValue={conf.sheetID}  onChange={(e) =>handleChange(e.target.value, "sheetID", i)}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="addressFormat">
          <Form.Label>Address Format</Form.Label>
          <Form.Control name="addressFormat" type="text" defaultValue={conf.addressFormat} onChange={(e) =>handleChange(e.target.value, "addressFormat", i)}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="featureColor">
          <Form.Label>Pin Color</Form.Label>
          <Form.Select name="featureColor" type="text" defaultValue={conf.featureColor} onChange={(e) =>handleChange(e.target.value, "featureColor", i)}> 
            <option value="red" color='red'>Red</option>
            <option value="orange">Orange</option>
            <option value="yellow">Yellow</option>
            <option value="olive">Olive</option>
            <option value="green">Green</option>
            <option value="teal">Teal</option>
            <option value="blue">Blue</option>
            <option value="violet">Violet</option>
            <option value="purple">Purple</option>
            <option value="pink">Pink</option>
            <option value="brown">Brown</option>
            <option value="grey">Grey</option>
            <option value="black">Black</option> 
          </Form.Select>
        </Form.Group>
      </div>
      )
      i++
    })
  
  return (
    <>
      <div  className="d-grid gap-2 navItem">
      <Button variant="primary"  onClick={handleShow}>
        Configure Data
      </Button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Data Configuration</Modal.Title>
        </Modal.Header>
        <div style={{paddingLeft: "1rem", paddingRight: "1rem"}}> 
          <Form onSubmit={handleSubmit}>
            {confFields}
            <Modal.Footer>
              <Button style={{textAlign: "left"}} onClick={addMap}>Add Map</Button>
              <Button type="submit">Save Conf</Button>
              <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
          </Form>
        </div>
      
      </Modal>
    </>
  )
    }
}




export default Conf;