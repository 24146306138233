

const isLocal = () => {
    const url = window.location.href;
    if (url.indexOf('localhost') > -1) {
        return true;
    } else {
        return false;
    }
}
export {isLocal}