import React from 'react';
import { Navbar, Container, Offcanvas, NavDropdown} from 'react-bootstrap';
import Auth from './Nav/Auth'
import Conf from './Conf'
import Refresh from './Refresh'
import {

  Link
} from "react-router-dom";




const LoggedInContents = ({props}) => {
  const log = window.log("LoggedInContents")
  if (props.user) {
    log.log("user present")
    return (
    <div className="navContents">
    <Conf props={props}/>
    <Refresh props={props}/>
    <NavDropdown.Divider style={{marginTop: '2rem', marginBottom: '2rem'}} />
  </div>
  )} else {
    return <div style={{paddingTop: "1rem"}}/>
  }
}

const Nav = (props) => {
  return (
    <Navbar bg="light" expand={false} className="nav">
    <Container fluid>
    <Navbar.Toggle aria-controls="offcanvasNavbar" />
      <Navbar.Brand href="/">Spread Maps
      <img className="logo" src="/spreadMap.png" alt="Logo" />
      </Navbar.Brand>

      <Navbar.Offcanvas
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="start"
      >
        <Offcanvas.Header closeButton>
          <Navbar.Brand id="offcanvasNavbarLabel" style={{color: "black"}} href="/">Spread Maps</Navbar.Brand>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="navContainer">

            <LoggedInContents props={props}/>
            <div className="navTray">
         
              <div/>
              <div  className="d-grid gap-2" style={{textAlign: "center"}}>
                <Auth auth={props.auth} firebase={props.firebase} user={props.user} />
              </div>
                <div style={{textAlign: "center", paddingTop: "1rem"}}>
    
                <Link style={{paddingRight: ".5rem"}} to="/tos">Terms of Service</Link>| 
                <Link style={{paddingLeft: ".5rem"}} to="/privacy">Privacy Policy</Link>

                </div>
            </div>
            
          </div>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Container>
  </Navbar>

  )


}




export default Nav;