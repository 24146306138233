import React, {useEffect, useState} from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import './App.css';
import "semantic-ui-css/semantic.min.css";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/functions';
import { useAuthState } from 'react-firebase-hooks/auth';
import Map from './components/Map';
import TOS from './components/TOS';
import Privacy from './components/Privacy';
import Nav from './components/Nav';
import NoSession from './components/NoSession';
import {isLocal} from './lib/env'
import {get } from'./lib/session'
const { v4: uuidv4 } = require('uuid');
const logSessionID = uuidv4()
console.log({logSessionID})
const Logger = require("./lib/common/react/logger")
window.log = (reactComponent) => {
  const log = new Logger()
  log.init("SpreadMaps", {reactComponent, logSessionID})
  return log
}

const log = window.log("App-Main")

firebase.initializeApp({
  apiKey: "AIzaSyCStVhAqePB3I4OjeraC9OHs3TZcBCGLj4",
  authDomain: "walsys-maps.firebaseapp.com",
  projectId: "walsys-maps",
  storageBucket: "walsys-maps.appspot.com",
  messagingSenderId: "455372153788",
  appId: "1:455372153788:web:62cfa99780ee9f57fd41b9",
  measurementId: "G-EWJDD1N9V3"
})
if (isLocal()) {
  firebase.functions().useEmulator("localhost", 5001);
}
const auth = firebase.auth();
function App() {
  const stateManage = require('./lib/state');
  const [user] = useAuthState(auth);
  const [mapInfo, setMapInfo] = useState();


  const updateSession = () => {
    if (typeof user?.auth === "object") {
      stateManage.init(firebase, user)
    }
    const sessionMap = get('maps')
    log.log({sessionMap})
    if (JSON.stringify(sessionMap) !== JSON.stringify(mapInfo)) {
      setMapInfo(sessionMap)
    }
  }
  useEffect(() => {
    updateSession()
  })
  return (
    <div className="App">
       <BrowserRouter>
      <header>
        <Nav user={user} auth={auth} firebase={firebase}/>
      </header>
      <main>     
      <Routes>
          <Route path="/" element={user ? <Map user={user} auth={auth} firebase={firebase} mapInfo={mapInfo}/> : 
            <NoSession  user={user} auth={auth} firebase={firebase}/>} />
           <Route path="/tos" element={<TOS />} />
           <Route path="/privacy" element={<Privacy />} />
       </Routes>

</main>
</BrowserRouter>
    </div>
  );
}





export default App;