import React, {useState, useEffect} from 'react';
import { Icon, Loader, Dimmer, Header } from "semantic-ui-react";
import GoogleMaps from './Map/GoogleMaps'
import DataModal from './Map/DataModal';
import Conf from './Conf'
const session = require('../lib/session')


const Map = (props) => {
  const log = window.log("Map")
  const [mapData, setMapData] = useState();
  const [selectedFeature, selectFeature] = useState()
  const [showFeatureData, toggleFeatureData] = useState(false)
  const [initState , setInitState] = useState({init: false})
  
  const getSheetData = async (mapInfo, sessionInfo) => { //Sets state with retrieved sheet data
    const sheetData = {sheetID: mapInfo.sheetID ,addressFormat: mapInfo.addressFormat, accessToken: sessionInfo.credential.accessToken, useCached: true, idToken: sessionInfo.credential.idToken}
    const resp =  await props.firebase.functions().httpsCallable('sheets-getSheetData')(sheetData, { timeout:  540})
    log.log({resp})
    return resp.data
  }

  useEffect(() => {
    const initSession = async () => {
      if (initState.init === false) { //If initial state has not been set, run through init
        setInitState({init: true, loading: true}) //set loading to true
        const sessionInfo = session.get("auth");
        const conf = session.get("maps");
        const mapDataCollection = {}
        let i = 0
        let error = false //Used to check if any errors occur
        let complete = false //Used to check if at least on map has been loaded - with error this will determine if one or more maps can be shown, or if an error has occurred
        log.log(conf)
        for (const mapConf of conf.mapsConf) {
          if (mapConf?.sheetID && mapConf?.addressFormat) { //If logged in, map not loaded, and config info present
            try {
              const data = await getSheetData(mapConf, sessionInfo)
              mapDataCollection[i] = {data, mapInfo: mapConf}
              complete = true
              i++
            } catch (e) {
              error = true
              log.log({e})
            }
          }
        }
        setMapData(mapDataCollection)
        setInitState({init: true, loading: false, complete, error})

      }
    }
    initSession() 
  })


  const featureColor = (feature, color) => {
    if (selectedFeature?.Address === feature?.Address && selectedFeature?.Name === feature?.Name) {
      switch (color) {
        case "red":
          return "yellow"
        case "orange":
          return "olive"
        case "yellow":
          return "green"
        case "olive":
          return "teal"
        case "green":
          return "blue"
        case "teal":
          return "violet"
        case "blue":
          return "pink"
        case "violet":
          return "pink"
        case "purple":
          return "brown"
        case "pink":
          return "grey"
        case "brown":
          return "black"
        case "grey":
          return "yellow"
        case "black":
          return "olive"
        default: //Default contrast
          return "blue"
      }
    } else {
      if (typeof color !== "string" || color === "") { //Default color
        return "red"
      }
      return color
    }
  }

  const Features = (mapData) => {  //Renders all map features in array
    const log = window.log("Features")
    log.log({mapData})
    const features = [];
    const Feature = ({feature, color}) =>  <Icon name="map marker alternate" color={featureColor(feature, color)} size="big" onClick={() =>{ //Individual map feautre
      selectFeature(feature)
      toggleFeatureData(true)
  
    }}/>
    for (const layerID in mapData) { //Loop through each layer
      const layerFeatures = mapData[layerID] //Layer
      let i =0
      const addressData = layerFeatures.data.addressData
      const color = layerFeatures.mapInfo.featureColor
      for (const feature of addressData) {
        features.push(  
          <Feature
           key={`layer_${layerID}_feature_${i}`}
          lat={feature.mapMeta.lat}
          lng={feature.mapMeta.lng}
          feature={feature}
          color={color}
          />
          );
          i++
      }
    }
    
    return features;
  }


  if (initState.complete) { //Sheet data loaded and conf configured
    log.log("state loaded, rendering map")
    const longLatInfo = {total: 0, initLat: 0, initLng: 0} //Init Lat/long info
    for (const i in mapData) { //Loop through all maps
      const mapFeatures = mapData[i]
      longLatInfo.total += mapFeatures.data.addressData.length //Total number of features
      longLatInfo[i] = {total: mapFeatures.data.addressData.length, initLat: mapFeatures.data.meta.initLat, initLng: mapFeatures.data.meta.initLng} //Map specific meta data
    }// | 
    for (const i in longLatInfo) { //Loop through all maps
      if (typeof longLatInfo[i] === "object") { //If value is map (ie. not total count)
        longLatInfo.initLat += longLatInfo[i].initLat * (longLatInfo[i].total/longLatInfo.total) //Calculate average lat for each map weighted by number of features
        longLatInfo.initLng += longLatInfo[i].initLng * (longLatInfo[i].total/longLatInfo.total) //Calculate average lng for each map weighted by number of features
      }
    }
    log.log({longLatInfo})
    const center = {
      lat: longLatInfo.initLat,
      lng: longLatInfo.initLng
    }
    return (
      <div>
        <GoogleMaps 
        zoom={11}
        center={center}
        features={Features(mapData)}
        />
        <DataModal 
        selectedFeature={selectedFeature}  
        showFeatureData={showFeatureData} 
        toggleFeatureData={(bool) => toggleFeatureData(bool)} 
        />
      </div>
    );
  } else if (initState.loading) { //Sheet data not loaded but conf configured , but no error
    return (
      <div>
      <Dimmer active inverted>
        <Loader indeterminate>Preparing Your Map</Loader>
      </Dimmer>
       </div>
    )
} else if (initState.error) {  //Error
  return (
      <div style={{textAlign: "center", paddingTop: "10rem"}}>
        <Header as='h2' icon>
          <Icon name='bug' />
          Error
          <Header.Subheader>
          We were unable to load your map. Please check your configuration and try again.
          </Header.Subheader>
          <div style={{paddingTop: "1rem"}}>
          <Conf props={props}/>
          </div>
        </Header>
        </div>
      )
    }
  else {
  return (
    <div style={{textAlign: "center", paddingTop: "10rem"}}>
  <Header as='h2' icon>
    <Icon name='settings' />
    Map Settings
    <Header.Subheader>
     Configure your map settings in the config tab
    </Header.Subheader>
    <div style={{paddingTop: "1rem"}}>
    <Conf props={props}/>
    </div>
  </Header>
     </div>
  )
}
}

export default Map;