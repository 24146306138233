import { Button, Modal, Form } from 'react-bootstrap';
import React from 'react';

const DataModal =  (props) => {
      const FeatureTable = (selectedFeature) => {
            if (selectedFeature)  {
              const formValues = []
              for (const key in selectedFeature) {
                const value = selectedFeature[key]
                if (typeof value === "string") {
                  formValues.push(
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>{key}</Form.Label>
                      <Form.Control type="text" defaultValue={value} readOnly/>
                    </Form.Group>
                  )
                }
              }
              return (
                <>
                 <Form>
                  {formValues}
                   </Form>
                 
                </>
              ) 
              }else {
                return (
                  <div>
                </div>)
              }
          }

      return (
            <Modal show={props.showFeatureData} onHide={() => props.toggleFeatureData(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Row Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>{FeatureTable(props.selectedFeature)}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={() => props.toggleFeatureData(false)}>
            Close
            </Button>
            </Modal.Footer>
      </Modal>
      )


    


}


export default DataModal;