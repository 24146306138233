

const set = (object, key) => {
    localStorage.setItem(`session_${key}`,JSON.stringify(object));  
}

const get = (key) => {
    const log = window.log("firebase_get")
    try {
        return JSON.parse(localStorage.getItem(`session_${key}`));
    } catch (error) {
        log.log({error})
        return {} //empty object as session not set.
    }
}

const append = (k, v, key) => {
    let object
    try {
        object= JSON.parse(localStorage.getItem('session'));
    } catch (error) {
        object=  {} //empty object as session not set.
    }
    object[k]=v;
    localStorage.setItem(`session_${key}`,JSON.stringify(object));  
}

export {set, get, append}