/*
Recomended to set in app.js like below:

const { v4: uuidv4 } = require('uuid');
const logSessionID = uuidv4()
console.log({logSessionID})
const Logger = require("./lib/common/react/logger")
window.log = (reactComponent) => {
  const log = new Logger()
  log.init("SpreadMaps", {reactComponent, logSessionID})
  return log
}
Components can instantiate instance with:

cosnt log = window.log("COMPONENT-NAME")
log.log("something interesting")
*/
const axios = require('axios');
const comLog = require("../../all/commonLib/logger")

module.exports =class Logger {
    async resolve () { //Resolve all promises - to be called at the end of execution
        await Promise.all(this.promises);
    }
    init (site, logMeta) {
        this.comLog = new comLog()
        if (typeof logMeta !== "object") { //Default value for append
            logMeta = {}
        }
        if (!logMeta.site) { //Define site as site unless explicitly defined otherwise
            logMeta.site = site
        }
        if (window.location.hostname === "localhost" || /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/.test(window.location.hostname)) { //If IP is localhost or IP
            this.stage = "dev"
        } else {
            this.stage = "pr"
        }
    
        logMeta.stage = this.stage
        console.log({logMeta})
        this.comLog.init(`React-${site}`, logMeta) //short message and appent
        this.promises = []
    }
    log (message, level) {
        if (this.stage === "dev") {
            if (typeof message === "string" || typeof message === "number")  {
                console.log(`Remote Logging: ${message}`) //Log message through console.log
            } else {
                console.log(`Remote Logging: ${JSON.stringify(message)}`) //Log message through console.log
            }

        }
        const {payload, headers, endpoint} = this.comLog.log(message, level)
        if (!payload.logMeta.href) {
            payload.logMeta.href = window.location.href
        }
        this.promises.push(axios.post(endpoint, payload, {headers})); //Push promise with config to be resolved later
    }
}

