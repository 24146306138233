import { Icon } from "semantic-ui-react";
import Auth from './Nav/Auth'

const NoSession = (props) => {
    const log = window.log("NoSession")
    log.log("No Session")
    return (
        <div style={{textAlign: "center", paddingTop: "10rem"}} className="fade_in">
             <Icon name='sign in' size='massive' />
            <h1>Please Sign In</h1>
            <p>Please sign in to continue.</p>
            <Auth auth={props.auth} firebase={props.firebase} user={props.user}/>
        </div>
    )
}

export default NoSession;